<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Remote RTB Zone</strong>
            </div>
            <div class="text-muted">Create new Remote RTB Zone</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Advertiser">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Advertiser of the Remote RTB Zone.</span>
                      </template>
                      <a-select
                        v-decorator="['advertiser', { initialValue: initialValue, rules: [{ required: true, message: 'Please select advertiser.' }] }]"
                        placeholder="Please select an advertiser" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in advertiser.list.filter(ele => ele.Status === 0)" :key="obj.Id">
                          [{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="Remote RTB Zone Name" v-decorator="['name', {
                        rules: [
                          { required: true, message: 'Name is required.' },
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zone URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone's URL.</span>
                      </template>
                      <a-input type="url" placeholder="Zone URL" v-decorator="['zoneUrl', {
                        rules: [
                          { required: true, message: 'Zone URL is required.' },
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone's description.</span>
                      </template>
                      <a-input placeholder="Description" v-decorator="['description', {
                        rules: [
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auction Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Type of RTB auction on advertiser side.</span>
                      </template>
                      <a-select v-decorator="['auctionType', { initialValue: '2' }]" placeholder="Auction Type"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option value='1'>First Price</a-select-option>
                        <a-select-option value='2'>Second Price Plus</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auction Type From Zone">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Get type of RTB auction from connected zone.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="auctionTypeFromZone" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum time in milliseconds to wait for the response from Remote RTB Zone's
                          server.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Tmax" v-decorator="['tmax', { initialValue: 350 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tmax From Zone">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Get Tmax for RTB auction from connected zone.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="tmaxFromZone" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impression Tracking Method">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>How advertiser tracks impressions for Remote RTB Zone. System will fire chosen pixel when
                          impression occurs (nurl or burl). If Markup Display value is chosen, the system will not
                          notify advertiser's server and advertiser will count impressions when their markup is
                          displayed. This value has an effect only for the impressions from connected Banner and Video
                          Zones, but not from RTB Zones.</span>
                      </template>
                      <a-select v-decorator="['trackingMethod', { initialValue: 2 }]"
                        placeholder="Impression Tracking Method" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option :value="0">
                          nurl
                        </a-select-option>
                        <a-select-option :value="1">
                          burl
                        </a-select-option>
                        <a-select-option :value="2">
                          Markup Display
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. QPS">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum queries per second - Maximum number of requests to make to the Remote RTB Zone in
                          one
                          second. Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Max. QPS"
                        v-decorator="['maxQps', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auto Optimize">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Automatic requests optimization.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled"
                        v-model="autoOpt" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to make per day. The system will even out distribution of requests throughout
                          the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator}] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the
                          day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator}] }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator}] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Revenue Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to earn from advertiser. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Revenue Daily Limit"
                        v-decorator="['revenueDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Filters</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Banner Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Banner Ads from connected zones.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="bannerEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Video Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Video Ads from connected zones.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="videoEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Audio Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Audio ads from connected zones.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="audioEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Native Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Native Ads from connected zones.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="nativeEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Web Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Web traffic from connected zones.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="webEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable App traffic from connected zones.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="appEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has Bidfloor
                          greater than this value.</span>
                      </template>
                      <a-input type="float" min="0" max="20" step="0.0001" placeholder="Max. Bidfloor"
                        v-decorator="['maxBidfloor', { initialValue: 20, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Min. Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has Tmax less
                          than this value.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Min. Tmax"
                        v-decorator="['minTmax', { initialValue: 10 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. IVT">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has inventory with
                          IVT greater than this value.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['maxIvt', { initialValue: 10 }]"
                        placeholder="Max. IVT">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. SIVT">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has inventory with
                          SIVT greater than this value.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['maxSivt', { initialValue: 10 }]"
                        placeholder="Max. SIVT">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Domains/Brands List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher domains or brand names.</span>
                      </template>
                      <a-select v-decorator="['domainsBrandsListMode', { initialValue: 0 }]"
                        placeholder="Domains/Brands List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Domains/Brands List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Domain Lists to block or allow.</span>
                      </template>
                      <a-select v-decorator="['domainsBrandsList', { initialValue: [] }]"
                        placeholder="Please Select Domains/Brands Lists" :showSearch="true" :filterOption="true"
                        mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeDomainLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select v-model="domainsBrandsList" placeholder="Domains/Brands List" :showSearch="true"
                          :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-secondary copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i></a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Apps List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher applications by their application identifiers.</span>
                      </template>
                      <a-select v-decorator="['appsListMode', { initialValue: 0 }]" placeholder="Apps List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Apps List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of App Lists to block or allow.</span>
                      </template>
                      <a-select v-decorator="['appsList', { initialValue: [] }]" placeholder="Please Select App Lists"
                        :showSearch="true" :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeAppLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select v-model="appsList" placeholder="Apps List" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('apps')"><i
                            class="fa fa-copy"></i></a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publishers List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publishers.</span>
                      </template>
                      <a-select v-decorator="['publishersListMode', { initialValue: 0 }]"
                        placeholder="Publishers List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publishers List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Publishers to block or allow.</span>
                      </template>
                      <a-select v-decorator="['publishersList']" placeholder="Please select Publishers" :showSearch="true"
                        :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activePublishers" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Non Empty Referer">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Ad impression or click with no referer will be filtered.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled"
                        v-model="nonEmptyRefererEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Referer Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Referer in ad impression or click and site URL should match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="refererMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's IP address in ad impression or click and user IP parameter should match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="ipMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="UA Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's User Agent in ad impression or click and user agent parameter should
                          match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="uaMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item  class="mt-10" :label-col="labelCol" :wrapper-col="wrapperCol" label="Sizes List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow creatives' sizes.</span>
                      </template>
                      <a-select v-model="sizesListMode" placeholder="Blacklist" defaultValue="0" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option value='0'>Blacklist</a-select-option>
                        <a-select-option value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sizes List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of creatives' sizes to block or allow.</span>
                      </template>
                      <a-select v-decorator="['sizesList']" placeholder="Please select Sizes" :showSearch="true"
                        :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option :value="`${size.size.width}x${size.size.height}`" v-for="(size, index) in sizes"
                          :key="index">{{ size.size.width }}x{{ size.size.height }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pub ID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Pub IDs.</span>
                      </template>
                      <a-select v-decorator="['pubIdListMode', { initialValue: 0 }]" placeholder="Pub ID List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pub ID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Pub IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="pubIdList" placeholder="Pub ID List" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('pubIds')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Seat List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow buyer seats (e.g., advertisers, agencies).</span>
                      </template>
                      <a-select v-model="seatListMode" placeholder="Blacklist" defaultValue="0" :filterOption="true"
                                optionFilterProp="children">
                        <a-select-option value='0'>Blacklist</a-select-option>
                        <a-select-option value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Seat List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of buyer seats to block or allow.</span>
                      </template>
                      <a-select v-model="seatList" placeholder="Seat List" :filterOption="true"
                                optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Creative IDs</span>
                      </template>
                      <a-select v-decorator="['cridListMode', { initialValue: 0 }]" placeholder="CRID List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Creative IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="cridList" placeholder="CRID List" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('crids')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Blacklists">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of IP Lists to use for IP block. Only "Not Global" IP Lists can be chosen.</span>
                      </template>
                      <a-select v-decorator="['ipBlacklists', { initialValue: [] }]" placeholder="Please select IP Lists"
                        :showSearch="true" :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeIpLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Categories Filter</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Enable IAB Categories">
                <a-tree checkable v-model="checkedKeys" :auto-expand-parent="autoExpandParent"
                  :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                  optionFilterProp="children" />
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Remote RTB Zone</button>
                  <button type="button" @click="$router.push({ name: 'display-remotertbzones' })"
                    class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { createRemoteRTBZone } from '@/api/display/remotertbzone'
import { getIabCategories } from '@/api/info'
import zoneSize from '@/views/display/data/zoneSizeRemoteRTBZones.json'
import Vue from 'vue'

export default {
  components: {},
  computed: {
    ...mapState(['advertiser']),
    ...mapGetters('ORTBpublisher', ['getPublishers', 'getActivePublishers']),
    ...mapGetters('ORTBiplist', ['getIpLists', 'getActiveIpLists']),
    ...mapGetters('applist', ['getAppLists', 'getActiveAppLists']),
    ...mapGetters('domainlist', ['getDomainLists', 'getActiveDomainLists']),
    activePublishers: function () {
      return this.getActivePublishers
    },
    activeIpLists: function () {
      return this.getActiveIpLists.filter(el => el.Global === 0)
    },
    activeAppLists: function () {
      return this.getActiveAppLists.filter(el => el.Global === 0)
    },
    activeDomainLists: function () {
      return this.getActiveDomainLists.filter(el => el.Global === 0)
    },
    seatListString() {
      return this.seatList.join('\n')
    },
    pubIdListString() {
      return this.pubIdList.join('\n')
    },
    cridListString() {
      return this.cridList.join('\n')
    },
    // initialValue() {
    //   if (this.$route.params.campaignId) {
    //     return this.advertiser.list.filter(ele => ele.Id === parseInt(this.$route.params.campaignId))[0].Id
    //   } else {
    //     return []
    //   }
    // },
    initialValue() {
      if (this.$route.params.advertiserId) {
        const advertiser = this.advertiser.list.find(ele => ele.Id === parseInt(this.$route.params.advertiserId))
        return advertiser === undefined ? [] : advertiser.Id
      } else {
        return []
      }
    },
  },
  data() {
    return {
      sizes: zoneSize,
      pubIdList: [],
      cridList: [],
      unformattedResponse: [],
      treeData: [],
      selectedKeys: [],
      autoExpandParent: false,
      checkedKeys: [],
      auctionTypeFromZone: true,
      tmaxFromZone: true,
      bannerEnabled: true,
      videoEnabled: true,
      audioEnabled: true,
      nativeEnabled: true,
      webEnabled: true,
      appEnabled: true,
      nonEmptyRefererEnabled: true,
      autoOpt: true,
      refererMatchEnabled: true,
      ipMatchEnabled: true,
      uaMatchEnabled: true,
      seatList: [],
      seatListMode: undefined,
      sizesListMode: undefined,
      // domainsBrandsListMode: undefined,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    copyList(type) {
      if (type === 'pubIds') {
        navigator.clipboard.writeText(this.pubIdList)
        Vue.prototype.$notification.success({
          message: 'Pub ID List copied to clipboard.',
        })
      } else if (type === 'crids') {
        navigator.clipboard.writeText(this.cridList)
        Vue.prototype.$notification.success({
          message: 'CRID List copied to clipboard.',
        })
      }
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.SeatList = this.seatListString
          values.PubIdList = this.pubIdListString
          values.CridList = this.cridListString
          values.AdAppsBlacklist = this.adAppsBlacklistString
          values.AuctionTypeFromZone = this.auctionTypeFromZone ? 1 : 0
          values.TmaxFromZone = this.tmaxFromZone ? 1 : 0
          values.BannerEnabled = this.bannerEnabled ? 1 : 0
          values.VideoEnabled = this.videoEnabled ? 1 : 0
          values.AudioEnabled = this.audioEnabled ? 1 : 0
          values.NativeEnabled = this.nativeEnabled ? 1 : 0
          values.WebEnabled = this.webEnabled ? 1 : 0
          values.AppEnabled = this.appEnabled ? 1 : 0
          values.NonEmptyReferer = this.nonEmptyRefererEnabled ? 1 : 0
          values.RefererMatch = this.refererMatchEnabled ? 1 : 0
          values.IpMatch = this.ipMatchEnabled ? 1 : 0
          values.UaMatch = this.uaMatchEnabled ? 1 : 0
          values.AutoOptimize = this.autoOpt ? 1 : 0
          if (values.zoneUrl !== '' && values.zoneUrl) {
            values.zoneUrl = values.zoneUrl.replace(/ /g, '')
          }
          this.seatListMode === undefined ? values.SeatListMode = 0 : values.SeatListMode = 1
          this.sizesListMode === undefined ? values.SizesListMode = 0 : values.SizesListMode = 1
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.IabCategories = newArr
          createRemoteRTBZone(values).then(response => {
            if (response.Status === 'Ok') {
              this.$notification.success({
                message: 'Remote RTB Zone Created',
                description: `Remote RTB Zone ${response.Remotertbzone.Name} has been successfully created.`,
              })
              // this.$router.push('/display/remotertbzone/update/' + response.Remotertbzone.Id)
              this.$router.push({
                name: 'display-remotertbzone-update',
                params: { id: response.Remotertbzone.Id },
              }).catch(() => {
              })
            } else if (typeof response.type !== 'undefined' && response.type === 'error') {
              this.$notification.success({
                message: 'Remote RTB Zone Created',
                description: 'Remote RTB Zone has been successfully created.',
              })
            }
          }).catch(err => console.log(err))
          // this.$store.dispatch('remotertbzone/CREATE_ZONE', { payload: values, callback: function() { this.$router.push('/remotertbzones') }.bind(this) })
        }
      })
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
  },
  created() {
    getIabCategories().then(response => {
      const resultArray = response.flatMap(obj => {
        const subnameArray = obj.Subcategories.map(subname => subname.Name)
        return [obj.Name, ...subnameArray]
      }).filter(name => name !== undefined)
      this.checkedKeys = resultArray
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS_SHORT')
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
    this.$store.dispatch('ORTBiplist/LOAD_IPLISTS')
    this.$store.dispatch('applist/LOAD_APPLISTS')
    this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.select-width {
  width: 90%;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }

  .mt-10 {
    margin-top: 3.76rem;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
